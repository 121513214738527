<template>
  <div>
    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        variant="success"
        label="Spinning"
        block
        v-if="loading"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <Statistics :ticket="ticket" v-if="!loading" />
    <TicketList :ticket="ticket" v-if="!loading" />
  </div>
</template>

<script>
import TicketList from "./TicketList.vue";
import Statistics from "./Statistics.vue";
import { mapActions, mapGetters } from "vuex";
import { BSpinner } from "bootstrap-vue";

export default {
  name: "DashboardSupport",
  components: {
    Statistics,
    TicketList,
    BSpinner,
  },

  computed: {
    ...mapGetters("ticketModule", {
      ticket: "ticket",
      loading: "loading",
    }),
  },
  methods: {
    ...mapActions("ticketModule", ["getTicketListAction"]),
  },

  async mounted() {
    await this.getTicketListAction()
      .then(() => {})
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
