<template>
  <section class="table-responsive">
    <!-- <b-button class="my-2" to="/support/submit" target="_blank"> -->
    <b-button class="my-2" v-b-modal.modal-main target="_blank">
      Submit a ticket
    </b-button>

    <b-modal
      id="modal-main"
      cancel-variant="outline-secondary"
      centered
      title="Add Ticket Form"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Type" label-for="h-Type" label-cols-md="4">
              <b-form-input id="h-Type" v-model="type" type="text" placeholder="type" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Name" label-for="h-Name" label-cols-md="4">
              <b-form-input id="h-Name" v-model="name" type="text" placeholder="name" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Requester"
              label-for="h-first-Requester"
              label-cols-md="4"
            >
              <b-form-input
                id="h-Requester"
                v-model="email"
                type="Email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Subject" label-for="h-Subject" label-cols-md="4">
              <b-form-input
                id="h-Subject"
                v-model="remark"
                type="text"
                placeholder="Subject"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Product / Service"
              label-for="h-Product"
              label-cols-md="4"
            >
              <b-form-select
                v-model="ticketData.itemId"
                :options="product"
                @change="productSelected(itemId)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Customer" label-for="h-Customer-Id" label-cols-md="4">
              <b-form-select
                v-model="ticketData.customerId"
                :options="optionsCustomer"
                @change="customerSelected(customerId)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Priority" label-for="h-Priority" label-cols-md="4">
              <b-form-select v-model="selected" :options="options" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Phone" label-for="h-Phone" label-cols-md="4">
              <b-form-input v-model="phone" class="mb-2 mb-lg-0" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Email" label-for="h-Email" label-cols-md="4">
              <b-form-input v-model="email" class="mb-2 mb-lg-0" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Channel" label-for="h-Channel" label-cols-md="4">
              <b-form-input v-model="channel" class="mb-2 mb-lg-0" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Description" label-for="h-Description" label-cols-md="4">
              <b-form-textarea v-model="description" class="mb-2 mb-lg-0" />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              @click="submitTicket()"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-edit"
      cancel-variant="outline-secondary"
      centered
      title="Edit Ticket Form"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Name" label-for="h-Name" label-cols-md="4">
              <b-form-input
                id="h-Name"
                v-model="ticketData.name"
                type="text"
                placeholder="name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Requester"
              label-for="h-first-Requester"
              label-cols-md="4"
            >
              <b-form-input
                id="h-Requester"
                v-model="ticketData.email"
                type="Email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Subject" label-for="h-Subject" label-cols-md="4">
              <b-form-input
                id="h-Subject"
                v-model="ticketData.remark"
                type="text"
                placeholder="Subject"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Product / Service"
              label-for="h-Product"
              label-cols-md="4"
            >
              <b-form-select
                v-model="ticketData.itemId"
                :options="product"
                @change="productSelected(itemId)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Customer" label-for="h-Customer-Id" label-cols-md="4">
              <b-form-select
                v-model="ticketData.customerId"
                :options="optionsCustomer"
                @change="customerSelected(customerId)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Priority" label-for="h-Priority" label-cols-md="4">
              <b-form-select v-model="ticketData.priority" :options="options" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Phone" label-for="h-Phone" label-cols-md="4">
              <b-form-input v-model="ticketData.phone" class="mb-2 mb-lg-0" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Email" label-for="h-Email" label-cols-md="4">
              <b-form-input v-model="ticketData.email" class="mb-2 mb-lg-0" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Channel" label-for="h-Channel" label-cols-md="4">
              <b-form-input v-model="ticketData.channel" class="mb-2 mb-lg-0" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Description" label-for="h-Description" label-cols-md="4">
              <b-form-textarea v-model="ticketData.description" class="mb-2 mb-lg-0" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Status" label-for="h-Status-Id" label-cols-md="4">
              <b-form-select v-model="ticketData.status" :options="statusOptions" />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              @click="EditTicket()"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-view"
      cancel-variant="outline-secondary"
      centered
      title="View Ticket Form"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Name" label-for="h-Name" label-cols-md="4">
              <b-form-input
                id="h-Name"
                v-model="ticketData.name"
                type="text"
                placeholder="name"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Requester"
              label-for="h-first-Requester"
              label-cols-md="4"
            >
              <b-form-input
                id="h-Requester"
                v-model="ticketData.email"
                type="Email"
                placeholder="Email"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Subject" label-for="h-Subject" label-cols-md="4">
              <b-form-input
                id="h-Subject"
                v-model="ticketData.remark"
                type="text"
                placeholder="Subject"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Product / Service"
              label-for="h-Product"
              label-cols-md="4"
            >
              <b-form-select
                v-model="ticketData.itemId"
                :options="product"
                @change="productSelected(itemId)"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Customer" label-for="h-Customer-Id" label-cols-md="4">
              <b-form-select
                v-model="ticketData.customerId"
                :options="optionsCustomer"
                @change="customerSelected(customerId)"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Priority" label-for="h-Priority" label-cols-md="4">
              <b-form-select v-model="ticketData.priority" :options="options" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Phone" label-for="h-Phone" label-cols-md="4">
              <b-form-input v-model="ticketData.phone" class="mb-2 mb-lg-0" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Email" label-for="h-Email" label-cols-md="4">
              <b-form-input v-model="ticketData.email" class="mb-2 mb-lg-0" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Channel" label-for="h-Channel" label-cols-md="4">
              <b-form-input v-model="ticketData.channel" class="mb-2 mb-lg-0" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Description" label-for="h-Description" label-cols-md="4">
              <b-form-textarea
                v-model="ticketData.description"
                class="mb-2 mb-lg-0"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Status" label-for="h-Status-Id" label-cols-md="4">
              <b-form-select
                v-model="ticketData.status"
                :options="statusOptions"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- submit and reset -->
          <!-- <b-col offset-md="4">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="EditTicket()" type="submit" variant="primary"
              class="mr-1">
              Submit
            </b-button>
          </b-col> -->
        </b-row>
      </b-form>
    </b-modal>

    <div>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="ticket"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(status)="data">
              <b-badge v-if="data.item.status === 0" variant="light-danger">
                Open
              </b-badge>
              <b-badge v-else variant="light-success"> Close </b-badge>
            </template>
            <template #cell(options)="data">
              <b-badge v-if="data.item.options === 0" variant="light-success">
                Low
              </b-badge>
              <b-badge v-if="data.item.options === 1" variant="light-warning">
                Medium
              </b-badge>
              <b-badge v-if="data.item.options === 2" variant="light-danger">
                High
              </b-badge>
            </template>

            <template #cell(actions)="data">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.modal-view
                  @click="getTicketDataView(data.item)"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>View</span>
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.modal-edit @click="getTicketData(data.item)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
          <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BForm,
  BModal,
  BAlert,
  BLink,
  BContainer,
  BFormCheckbox,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BForm,
    BModal,
    BAlert,
    BLink,
    BRow,
    BContainer,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    ToastificationContent,
  },
  // props: {
  //   ticket: {
  //     type: Array,
  //   },
  // },
  data() {
    return {
      type: "",
      description: "",
      itemId: "",
      customerId: "",
      status: "",
      tenant: "",
      assignedTo: "",
      selected: null,
      remark: "",
      email: "",
      phone: "",
      channel: "",
      name: "",
      priority: "",
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      ticketData: [],
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "name",
        { key: "type", label: "Ticket Type", sortable: true },
        { key: "description", label: "Description", sortable: true },
        // { key: "customerId", label: "Customer", sortable: true },
        // { key: "itemId", label: "Item", sortable: true },
        { key: "email", label: "Assigned To", sortable: true },
        "status",
        "priority",
        { key: "status", label: "Status", sortable: true },
        "Actions",
      ],
      description: "",
      name: "",
      remark: "",
      email: "",
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      product: [],
      optionsCustomer: [],
      statusOptions: [
        { value: "0", text: "Open" },
        { value: "1", text: "Close" },
      ],
      options: [
        { value: "0", text: "Low" },
        { value: "1", text: "Medium" },
        { value: "2", text: "High" },
      ],
      content: `<h1>Ticket</h1>
  <h2>Write your ticket description</h2>
  <p><br></p>
  <pre>
</pre>  `,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapGetters("ticketModule", {
      ticket: "ticket",
      loading: "loading",
    }),
    ...mapGetters("customerModule", {
      CustomerList: "customers",
      custId: "custId",
      loading: "loading",
    }),
    ...mapGetters("productModule", {
      ProductList: "product",
      productID: "productID",
      loading: "loading",
    }),
  },
  methods: {
    ...mapActions("ticketModule", [
      "addTicketAction",
      "getTicketListAction",
      "getTicketIdAction",
      "removeTicketAction",
      "updateTicketAction",
    ]),

    ...mapActions("productModule", ["getProductListAction", "getProductIDAction"]),
    ...mapActions("customerModule", ["getCustomersListAction", "getCustomersByIDAction"]),

    getTicketData(data) {
      this.ticketData = data;
    },

    getTicketDataView(data) {
      this.ticketData = data;
    },

    successAdd() {
      this.$swal({
        title: "Ticket Added!",
        text: "You have successfully added a ticket!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    errorAdd() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    successEdit() {
      this.$swal({
        title: "Ticket Updated!",
        text: "You have successfully updated a ticket!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    errorEdit() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    submitTicket() {
      let payload = {
        type: this.type,
        name: this.name,
        email: this.email,
        remark: this.remark,
        priority: this.selected,
        phone: this.phone,
        email: this.email,
        channel: this.channel,
        description: this.description,
      };

      this.addTicketAction(payload)
        .then(() => {
          this.successAdd();
          this.$bvModal.hide("modal-main");
        })
        .catch(() => {
          this.errorAdd();
          this.$bvModal.hide("modal-main");
        });
    },

    EditTicket() {
      let payload = this.ticketData;
      this.updateTicketAction(payload)
        .then(() => {
          this.successEdit();
          this.$bvModal.hide("modal-edit");
        })
        .catch(() => {
          this.errorEdit();
          this.$bvModal.hide("modal-edit");
        });
    },

    removeTicket(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeTicketAction(id);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Ticket has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Ticket is safe :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    productSelected(id) {
      this.getProductIDAction(id);
      this.productID = null;
    },

    customerSelected(id) {
      this.getCustomersByIDAction(id);
      this.custId = null;
    },
    // updateItemForm(ind, val) {
    //   this.invoiceData.items[ind] = this.ProductList.filter((v, i) => v.id == val)[0];
    // },
  },

  async mounted() {
    // Set the initial number of items
    this.totalRows = this.ticket.length;

    await this.getCustomersListAction().then(() => {
      this.CustomerList.map((v, i) =>
        this.optionsCustomer.push({
          value: v.id,
          text: `${(v.otherName ??= v.companyName)} ${(v.surname ??= "")}`,
        })
      );
    });

    await this.getProductListAction().then(() => {
      this.ProductList.map((v, i) => {
        this.product.push({ value: v.id, text: `${v.name}` });
      });
    });
  },
};
</script>
