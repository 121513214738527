<template>
  <div>
    <b-card no-body class="card-statistics">
      <b-card-header>
        <b-card-title>Tickets Status</b-card-title>
        <b-card-text class="mr-25 mb-0"> Updated 1 day ago </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col md="6" sm="6" class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-danger">
                  <feather-icon size="24" icon="SlashIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">Open : {{ statusCounts[0] }}</h4>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="6" sm="6" class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-success">
                  <feather-icon size="24" icon="CheckSquareIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">Closed : {{ statusCounts[1] }}</h4>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body class="card-statistics">
      <b-card-header>
        <b-card-title>Tickets Priorities</b-card-title>
        <b-card-text class="mr-25 mb-0"> Updated 1 day ago </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col md="4" sm="6" class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-success">
                  <feather-icon size="24" icon="AlertCircleIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">Low : {{ priorityCounts[0] }}</h4>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="4" sm="6" class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-warning">
                  <feather-icon size="24" icon="AlertOctagonIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">Medium : {{ priorityCounts[1] }}</h4>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="4" sm="6" class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-danger">
                  <feather-icon size="24" icon="AlertTriangleIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">High : {{ priorityCounts[2] }}</h4>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    ticket: {
      type: Array,
    },
  },
  computed: {
    statusCounts() {
      const data = this.ticket;
      const statusCounts = {
        0: 0,
        1: 0,
      };
      for (const item of data) {
        const status = item.status;
        statusCounts[status] += 1;
      }
      return statusCounts;
    },
    priorityCounts() {
      const data = this.ticket;
      const priorityCounts = {
        0: 0,
        1: 0,
        2: 0,
      };
      for (const item of data) {
        const priority = item.priority;
        priorityCounts[priority] += 1;
      }
      return priorityCounts;
    },
  },
  data() {
    return {
      TicketStatus: [
        {
          icon: "SlashIcon",
          color: "light-danger",
          title: "5",
          subtitle: "Unresolved",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserCheckIcon",
          color: "light-info",
          title: "8",
          subtitle: "Open",
          customClass: "mb-2 mb-xl-0",
        },
      ],
      statisticsItems: [
        {
          icon: "SlashIcon",
          color: "light-danger",
          title: "5",
          subtitle: "Unresolved",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserCheckIcon",
          color: "light-info",
          title: "8",
          subtitle: "Open",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "AlertTriangleIcon",
          color: "light-warning",
          title: "0",
          subtitle: "On Hold",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "CheckSquareIcon",
          color: "light-success",
          title: "15",
          subtitle: "Closed",
          customClass: "",
        },
      ],
    };
  },
};
</script>
